var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Blade-Item',{attrs:{"bladeName":"release-archive","bladesData":_vm.bladesData,"canDelete":false,"canEdit":false,"navigation":"release-archives","onPullSuccessAsync":_vm.pullRelease,"title":"Archived Release"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item.courierID != item.sellerID)?_c('BT-Field-Select',{attrs:{"alternateText":"Unknown","label":"Courier","navigation":"couriers"},model:{value:(item.courierID),callback:function ($$v) {_vm.$set(item, "courierID", $$v)},expression:"item.courierID"}}):_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Courier")]),_c('v-list-item-title',[_vm._v("Self")])],1)],1),_c('BT-Field-Select',{attrs:{"label":"Released From","navigation":"locations","itemText":"locationName"},model:{value:(item.departureLocationID),callback:function ($$v) {_vm.$set(item, "departureLocationID", $$v)},expression:"item.departureLocationID"}}),_c('BT-Field-Date',{attrs:{"label":"Released On","shortDateAndTime":""},model:{value:(item.releasedOn),callback:function ($$v) {_vm.$set(item, "releasedOn", $$v)},expression:"item.releasedOn"}}),_c('BT-Field-Select',{attrs:{"label":"Released By","navigation":"users","itemText":"userName"},model:{value:(item.releasedByUserID),callback:function ($$v) {_vm.$set(item, "releasedByUserID", $$v)},expression:"item.releasedByUserID"}}),_c('BT-Signature-Overlay',{attrs:{"buttonClass":"primary","block":"","canEdit":false,"color":"white","text":"Proof Of Release"},model:{value:(item.proofOfRelease),callback:function ($$v) {_vm.$set(item, "proofOfRelease", $$v)},expression:"item.proofOfRelease"}}),_c('v-expansion-panels',{model:{value:(_vm.panelV),callback:function ($$v) {_vm.panelV=$$v},expression:"panelV"}},[_c('BT-Blade-Expansion-Items',{attrs:{"canSelect":false,"canRefresh":false,"headers":[
                    { text: 'Name', value: 'measurement', display: true, title: 1 },
                    { text: 'Packed', value: 'quantityPacked', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Packed: ' },
                    { text: 'Released', value: 'quantityReleased', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Released: ' }],"hideActions":"","items":item.packagedItems,"title":"Packages"},scopedSlots:_vm._u([{key:"measurement",fn:function(ref){
                    var item = ref.item;
return [_c('BT-Entity',{attrs:{"navigation":"measurements","itemValue":item.measurementID,"itemText":"measurementName"}})]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }