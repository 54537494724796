<template>
    <BT-Blade-Item
        bladeName="release-archive"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        navigation="release-archives"
        :onPullSuccessAsync="pullRelease"
        title="Archived Release">
        <template v-slot="{ item }">

            <BT-Field-Select
                v-if="item.courierID != item.sellerID"
                v-model="item.courierID"
                alternateText="Unknown"
                label="Courier"
                navigation="couriers" />
            <v-list-item v-else>
                <v-list-item-content>
                    <v-list-item-subtitle>Courier</v-list-item-subtitle>
                    <v-list-item-title>Self</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <BT-Field-Select
                v-model="item.departureLocationID"
                label="Released From"
                navigation="locations"
                itemText="locationName" />

            <BT-Field-Date
                label="Released On"
                v-model="item.releasedOn"
                shortDateAndTime />

            <BT-Field-Select
                v-model="item.releasedByUserID"
                label="Released By"
                navigation="users"
                itemText="userName" />
            
            <BT-Signature-Overlay 
                v-model="item.proofOfRelease"
                buttonClass="primary"
                block
                :canEdit="false"
                color="white"
                text="Proof Of Release" />

            <v-expansion-panels v-model="panelV">
                <BT-Blade-Expansion-Items
                    :canSelect="false"
                    :canRefresh="false"
                    :headers="[
                        { text: 'Name', value: 'measurement', display: true, title: 1 },
                        { text: 'Packed', value: 'quantityPacked', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Packed: ' },
                        { text: 'Released', value: 'quantityReleased', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Released: ' }]"
                    hideActions
                    :items="item.packagedItems"
                    title="Packages">
                    <template v-slot:measurement="{ item }">
                        <BT-Entity 
                            navigation="measurements"
                            :itemValue="item.measurementID"
                            itemText="measurementName" />
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels>

            
        </template>
    </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';
import { measurementArea } from '~helpers';

export default {
    name: 'Release-Archive-Blade',
    components: {
        BTSignatureOverlay: () => import('~components/BT-Signature-Overlay.vue')
    },
    data: function() {
        return {
            panelV: 0
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        pullRelease(release) {
            if (this.isLengthyArray(release.packagedItems)) {
                release.packagedItems.sort(firstBy(x => measurementArea(x)));
            }

            return release;
        }
    }
}
</script>